.automatisierung-indicator {
    display: flex;
    gap: 5px;
    margin-bottom: 1rem;
}

.indicator-bar {
    width: 25px;
    height: 6.35px;
    background-color: rgb(160, 160, 160);
    border-radius: 4px;
}

.high .indicator-bar {
    background-color: rgb(22, 136, 68);
}

.medium .indicator-bar:nth-child(-n+3) {
    background-color: rgba(250, 216, 40, 1);
}

.medium .indicator-bar:nth-child(n+4) {
    background-color: rgb(160, 160, 160);
}

.low .indicator-bar:nth-child(1) {
    background-color: rgba(153, 0, 0, 1);
}

.low .indicator-bar:nth-child(n+2) {
    background-color: rgb(160, 160, 160);
}
