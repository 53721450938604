:root {
  --copy-MD-font-family: "Montserrat", Helvetica;
  --copy-MD-font-size: 14px;
  --copy-MD-font-style: normal;
  --copy-MD-font-weight: 500;
  --copy-MD-letter-spacing: 0px;
  --copy-MD-line-height: 20px;
  --copy-MD-strong-font-family: "Montserrat", Helvetica;
  --copy-MD-strong-font-size: 14px;
  --copy-MD-strong-font-style: normal;
  --copy-MD-strong-font-weight: 700;
  --copy-MD-strong-letter-spacing: 0px;
  --copy-MD-strong-line-height: 20px;
  --copy-SM-font-family: "Montserrat", Helvetica;
  --copy-SM-font-size: 12px;
  --copy-SM-font-style: normal;
  --copy-SM-font-weight: 500;
  --copy-SM-letter-spacing: 0px;
  --copy-SM-line-height: 16px;
  --copy-SM-strong-font-family: "Montserrat", Helvetica;
  --copy-SM-strong-font-size: 12px;
  --copy-SM-strong-font-style: normal;
  --copy-SM-strong-font-weight: 700;
  --copy-SM-strong-letter-spacing: 0px;
  --copy-SM-strong-line-height: 16px;
  --copy-XL-strong-font-family: "Montserrat", Helvetica;
  --copy-XL-strong-font-size: 18px;
  --copy-XL-strong-font-style: normal;
  --copy-XL-strong-font-weight: 700;
  --copy-XL-strong-letter-spacing: 0px;
  --copy-XL-strong-line-height: 28px;
  --lightsuccess: rgba(22, 136, 68, 1);
  --flowkitblue: rgba(0, 153, 255, 1);
  --flowkitcharcoal: rgba(34, 34, 34, 1);
  --flowkitgreen: rgba(41, 204, 106, 1);
  --flowkitpurple: rgba(123, 97, 255, 1);
  --flowkitred: rgba(252, 85, 85, 1);
  --flowkitwhite: rgba(255, 255, 255, 1);
  --lightbg: rgba(234, 236, 236, 1);
  --lightbg-surface: rgba(255, 255, 255, 1);
  --sf-bg-surface-hovered: rgba(247, 247, 247, 1);
  --lightbg-surface-primary: rgba(255, 0, 0, 1);
  --lightbg-surface-primary-hovered: rgba(204, 0, 0, 1);
  --lightborder: rgba(210, 212, 213, 1);
  --lightborder-active: rgba(53, 52, 61, 1);
  --background-light: rgba(100, 101, 108, 1);
  --lighttext: rgba(100, 101, 108, 1);
  --lighttext-interactive: rgba(53, 52, 61, 1);
  --lighttext-on-primary: rgba(255, 255, 255, 1);
  --lighttext-subdued: rgba(100, 101, 108, 1);
  --lighttext-success: rgba(22, 136, 68, 1);
  --project-color-1: rgba(228, 195, 173, 1);
  --project-color-2: rgba(255, 183, 103, 1);
  --project-color-3: rgba(217, 220, 118, 1);
  --project-color-4: rgba(209, 201, 180, 1);
  --project-color-5: rgba(223, 199, 134, 1);
  --project-color-6: rgba(209, 226, 202, 1);
  --project-color-7: rgba(245, 235, 220, 1);
  --project-color-8: rgba(255, 225, 115, 1);
  --shadow-light-low: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}
/* Global styles for body and html */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box; /* Ensure that padding and border are included in the element's total width and height */
}

/* Apply box-sizing to all elements */
*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
  background: var(--lightbg, #EAECEC);
  width: 100%; /* Ensure container spans the full width */
  max-width: 100vw; /* Prevent overflow */
}

.content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.headline-copy-instance {
  font-family: var(--copy-XL-strong-font-family);
  font-size: var(--copy-XL-strong-font-size);
  font-weight: var(--copy-XL-strong-font-weight);
  line-height: var(--copy-XL-strong-line-height);
}
.headline-copy-instance p {
  font-weight:  var(  --copy-MD-font-weight);
}

.frame-2, .frame-3, .frame-4, .frame-5, .frame-6, .frame-7, .frame-8 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.text-wrapper-5, .text-wrapper-6 {
  font-family: var(--copy-MD-strong-font-family);
  font-size: var(--copy-MD-strong-font-size);
  font-weight: var(--copy-MD-strong-font-weight);
  line-height: var(--copy-MD-strong-line-height);
  margin-bottom: 0.5rem;
}

.select-new-instance {
  display: flex;
  height: 3rem;
  padding: 1rem;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  font-family: var(--copy-MD-font-family);
  font-size: var(--copy-MD-font-size);
  font-style: normal;
  appearance: none;
  background: var(--light-bg-surface, #FFF) url('chevron.svg') no-repeat right 0.75rem center;
  font-weight: var(--copy-MD-font-weight);  color: black;
  line-height: 1.25rem; /* 142.857% */
  border-radius: 0.25rem;
  border: 1px solid var(--light-border, #D2D4D5);
}
.select-new-instance:hover,
.select-new-instance:focus {
  border-color: var(--lighttext-interactive, #35343D); /* Black border on hover and focus */
  outline: none; /* Remove default outline */
}

.select-new-instance:focus:active {
  border-color: var(--light-border, #D2D4D5); /* Reset border color on active (after clicking an option) */
}

.radio-button-base {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: -webkit-fill-available;
  border-radius: 0.25rem;
  background: var(--lightbg-surface, #FFF);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20);
  padding: 1rem;
  margin-bottom: 1rem;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}

.radio-option input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.25rem; /* Adjust the size as needed */
  height: 1.25rem; /* Adjust the size as needed */
  border: 1px solid var(--light-border, #D2D4D5);
  border-radius: 50%;
  position: relative;
  outline: none;
  cursor: pointer;
}

.radio-option input[type="checkbox"]::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: var(--lightsuccess, #FFF);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background 0.2s ease;
}

.radio-option input[type="checkbox"]:checked::before {
  background: var(--flowkitgreen, #29CC6A);
}

.radio-option input[type="checkbox"]:checked + label::before {
  background: var(--lightsuccess, #29CC6A) url('./checked.svg') no-repeat center center;
  background-size: 70%; /* Adjust the size of the check mark */
  border-color: var(--lightsuccess);
  background-position: center;
  background-repeat: no-repeat;
}

.radio-option label {
  color: var(--light-text-interactive, #35343D);
  display: flex;
  align-items: center;
}
.radio-option label::before {
  content: "";
  display: inline-block;
  width: 1.25rem; /* Adjust the size as needed */
  height: 1.25rem; /* Adjust the size as needed */
  margin-right: 0.5rem;
  border: 1px solid var(--lightborder);
  border-radius: 50%;
  vertical-align: middle;
}

.check-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-instance-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem; /* Adjust the size as needed */
  height: 1.25rem; /* Adjust the size as needed */
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

button {
  display: flex;
  height: 3.5rem;
  padding: 1rem 4rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background: var(--lightbg-surface-primary, #F00);
  color: var(--lighttext-on-primary, #FFF);
  font-family: var(--copy-MD-strong-font-family);
  font-size: var(--copy-MD-strong-font-size);
  font-weight: var(--copy-MD-strong-font-weight);
  line-height: var(--copy-MD-strong-line-height);
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: var(--lightbg-surface-primary-hovered, #C00);
}

/* Custom styles for checkboxes */
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--lightborder);
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
}

input[type="checkbox"]:checked + label::before {
  content: "";
  background-color: var(--flowkitgreen);
  border: 1px solid var(--flowkitgreen);
  background-image: url('checked.svg'); /* Ensure this path is correct */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

input[type="checkbox"]:checked + label {
  color: black;
}

label {
  color: var(--lighttext-interactive);
  font-family: var(--copy-MD-font-family);
  font-size: 0.875rem; /* 14px */
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 20px */
}

.result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 2rem;
  padding: 1rem;
  border: 1px solid var(--lightborder);
  border-radius: 0.25rem;
  background: var(--lightbg-surface);
  width: -webkit-fill-available;
}

.result h3 {
  font-family: var(--copy-MD-font-family);
  font-size: var(--copy-MD-font-size);
  font-weight: var(--copy-MD-font-weight);
  color: var(--lighttext-subdued);
  margin-bottom: 0;
}

.result h2 {
  font-family: var(--copy-XL-strong-font-family);
  font-size: var(--copy-XL-strong-font-size);
  font-weight: var(--copy-XL-strong-font-weight);
  color: var(--lighttext-interactive);
}
.result strong {
  display: block;
  text-align: justify;	
  font-family: var(--copy-MD-strong-font-family);
  font-size: var(--copy-MD-strong-font-size);
  font-weight: var(--copy-MD-strong-font-weight);
  margin-bottom: 0.5rem;
}
.result p {
  display: block;
  text-align: justify;	
  font-family: var(--copy-SM-font-family);
  font-size: var(--copy-SM-font-size);
  font-weight: var(--copy-SM-font-weight);
  color: var(--lighttext);
}

.result-card {
  padding: 1rem;
  background-color: #e6f7ff;
  border: 1px solid var(--lightborder);
  border-radius: 0.25rem;
  width: -webkit-fill-available;
  text-align: left;
}

.result-card strong {
  display: block;
  text-align: justify;	
  font-family: var(--copy-MD-strong-font-family);
  font-size: var(--copy-MD-strong-font-size);
  font-weight: var(--copy-MD-strong-font-weight);
  margin-bottom: 0.5rem;
}

.result-card p {
  margin: 0;
  font-family: var(--copy-MD-font-family);
  font-size: var(--copy-MD-font-size);
  font-weight: var(--copy-MD-font-weight);
  color: var(--lighttext-interactive);

}
.result-card ul {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 1rem;
  list-style-type: square;

}
.result-card li {
  margin: 0;
  font-family: var(--copy-MD-font-family);
  font-size: var(--copy-MD-font-size);
  font-weight: var(--copy-MD-font-weight);
  color: var(--lighttext-interactive);
  padding-left: 10px; /* Adjust as necessary */
  margin-bottom: 0.5rem; /* Adjust the spacing between items if needed */
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.reset-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  color: var(--lighttext-interactive);
  background-color: var(--lightbg-surface);
  border: 1px solid var(--lightborder);
  text-wrap: nowrap;
}

.reset-button:hover {
  color: var(--lighttext-interactive);
  background-color: var(--sf-bg-surface-hovered);
  border: 1px solid var(--lightborder);
}
.order-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  color: var(--lighttext-interactive);
  background-color: var(--lightbg-surface);
  border: 1px solid var(--lightborder);
  text-wrap: nowrap;
}

.order-button:hover {
  background-color: var(--sf-bg-surface-hovered);
  border: 1px solid var(--lightborder);
}

.order-link {
  color: var(--lighttext-interactive);
  font-family: var(--copy-MD-strong-font-family);
  font-size: var(--copy-MD-strong-font-size);
  font-weight: var(--copy-MD-strong-font-weight);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-wrap: nowrap;
}

.order-link:hover {
  text-decoration: underline;
}

.order-link::after {
  content: '›';
  margin-left: 0.25rem;
}
.reset-link {
  color: var(--lighttext-interactive);
  font-family: var(--copy-MD-strong-font-family);
  font-size: var(--copy-MD-strong-font-size);
  font-weight: var(--copy-MD-strong-font-weight);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-wrap: nowrap;
}

.reset-link:hover {
  text-decoration: underline;
}

.reset-link::after {
  content: '›';
  margin-left: 0.25rem;
}

.no-match {
  text-align: center;
}

.no-match h3 {
  text-align: left;
  font-family: var(--copy-XL-strong-font-family);
  font-size: var(--copy-XL-strong-font-size);
  font-weight: var(--copy-XL-strong-font-weight);
  color: var(--lighttext-interactive);
  margin-top: 0;
}

.no-match p {
  font-family: var(--copy-MD-font-family);
  font-size: var(--copy-MD-font-size);
  font-weight: var(--copy-MD-font-weight);
  color: var(--lighttext-interactive);
  text-align: left;
}

.automatisierung-section {
  display: wrap;
  align-items: center;
  gap: 10px; /* Adjust the gap as needed */
  flex-direction: row; /* Ensure items are aligned horizontally */
}

/* Media Queries for Responsiveness */
@media (max-width: 1030px) {
  .container {
    padding: 1rem 1rem 2rem 1rem;
  }

  .button-wrapper {
    justify-content: center;
  }

  .reset-button,
  .order-link,
  .reset-link,
  .order-button {
    width: 100%;
    margin-top: 0.5rem;
  }

  .frame-2,
  .frame-3,
  .frame-4,
  .frame-5,
  .frame-6,
  .frame-7,
  .frame-8 {
    margin-bottom: 0.75rem;
  }

  .radio-button-base {
    gap: 0.5rem;
    padding: 0.75rem;
  }

  .button {
    width: 100%;
    height: 3rem;
    padding: 0.75rem;
  }

  .button-group {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1rem 1rem 2.5rem 1rem;
    width: 100%;
    max-width: 100vw; /* Prevent overflow on mobile */
  }

  .headline-copy-instance {
    font-size: 1rem;
    line-height: 1.7rem;
    text-align: left;
  }
  .headline-copy-instance p {
    font-weight:  var(  --copy-MD-font-weight);
  }

  .select-new-instance {
    font-size: 0.75rem;
    padding-right: 2rem;
  }

  .radio-option {
    font-size: 0.75rem;
    padding-bottom: 1rem;
  }
  .result-card li {
    font-size: 0.75rem;
  }

  .button {
    height: 2.5rem;
    padding: 0.5rem 1rem;
  }

  .reset-button,
  .order-button {
    height: 2.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--lightbg-surface);
    border: 1px solid var(--lightborder);
  }

  .result h3,
  .result-card strong,
  .result-card p {
    font-size: var(--copy-SM-strong-font-size);
  }

  .result h2{
    font-size: var(--copy-MD-strong-font-size);
  }
}

@media (max-width: 380px) {
  .button-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }

  .reset-button,
  .order-button {
    width: 100%;
  }

  .order-link {
    width: 100%;
    height: 1.1rem;
  }
}
.test{
  width: 100%,
}
@media (max-width: 370px) {
  .automatisierung-indicator {
      flex-wrap: wrap;
  }

  .automatisierung-indicator .indicator-bar {
      margin-bottom: 5px; /* Add some space between lines */
  }
}




